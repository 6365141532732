import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoggerToggleService } from './services/logger-toggle.service';
import { filter } from 'rxjs/operators';
import { RouterOutletService } from './services/router.outlet.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('content', { static: true }) routerOutletElement: ElementRef;
  constructor(private loggerToggleService: LoggerToggleService, private router: Router,
    private routerOutletService: RouterOutletService) {
    this.loggerToggleService.disableConsoleInProduction();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       });
      });
  }
  ngAfterViewInit() {
    this.routerOutletService.setRouterOutlet(this.routerOutletElement);
  }
}
