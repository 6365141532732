// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'http://localhost:8100/',
  backendUrl: 'https://staging.bel.devkom.pro/', //FIXME change to local WP project url
  production: false,
  staging: false,
  mirror: false,
  appVersion: "staging 1.1107.1",
  showAlert: false,
  firebase: {
    apiKey: "AIzaSyBU07DW402GoyKB_ODsawAQOnPiWz99-0U",
    authDomain: "bel-staging.firebaseapp.com",
    projectId: "bel-staging",
    storageBucket: "bel-staging.appspot.com",
    messagingSenderId: "490676927713",
    appId: "1:490676927713:web:8f29e1250aa4a7980f0ba0"
  },
  algolia: {
    appId: 'D28YZRTGEQ',
    apiKey: '95ae5c945c44cc33d7a1ca38fdbfad3b',
  indexes: ['en_news', 'en_programs', 'en_opinion', 'pl_news', 'pl_programs', 'pl_opinion', 'ru_news', 'ru_programs', 'ru_opinion', 'ru_in_focus'],
  indexesBe: ['news', 'programs', 'opinion', 'in_focus'],
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
