import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
// const numberRegex = '^[0-9]*$';
const numberRegex = '*';

const regexMatcher = (url:any) => {
  let pathQuery = document.location.search;
  if(pathQuery.length){
    pathQuery = pathQuery.replace('?','');
    let queryArr = pathQuery.split('&');

  let tmp = queryArr[0].split('=');
  let id:any = false;
  if(tmp[0]==='p'){
    id = tmp[1];
  }else if(!isNaN(parseInt(tmp[0]))){
    id = tmp[0];
  }
  if(id){
    return {
      consumed: url,
      posParams: {
        id: id
      }
    }
  }
  }
  return null;
};
const routes: Routes = [
  {
    matcher: regexMatcher,
    loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule)
  },
  {
    path:'go',
    redirectTo:'/'
  },
  {
    path: 'program/abjektyu',
    redirectTo: '/in-focus'
  },
  {
    path: 'ru/program/abektyu',
    redirectTo: '/ru/in-focus'
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    pathMatch: 'full'
  },
  {
    path: 'ru',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    pathMatch: 'full'
  },
  {
    path: 'en',
    loadChildren: () => import('./pages/home-lng/home-lng.module').then(m => m.HomeLngPageModule),
    pathMatch: 'full'
  },
  {
    path: 'pl',
    loadChildren: () => import('./pages/home-lng/home-lng.module').then(m => m.HomeLngPageModule),
    pathMatch: 'full'
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'ru/news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'en/news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'pl/news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'chanel-news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'ru/chanel-news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'en/chanel-news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'pl/chanel-news',
    loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
  },


  {
    path: 'article-news/:slug',
    loadChildren: () => import('./pages/news-article-category/article.module').then(m => m.NewPageModule)
  },
  {
    path: 'ru/article-news/:slug',
    loadChildren: () => import('./pages/news-article-category/article.module').then(m => m.NewPageModule)
  },
  {
    path: 'en/article-news/:slug',
    loadChildren: () => import('./pages/news-article-category/article.module').then(m => m.NewPageModule)
  },
  {
    path: 'pl/article-news/:slug',
    loadChildren: () => import('./pages/news-article-category/article.module').then(m => m.NewPageModule)
  },

  {
    path: 'article-new/merkavanne',
    loadChildren: () => import('./pages/news-opinion-category/newsOpinion.module').then(m => m.NewsOpinionPageModule)
  },
  {
    path: 'ru/article-new/merkavanne',
    loadChildren: () => import('./pages/news-opinion-category/newsOpinion.module').then(m => m.NewsOpinionPageModule)
  },
  {
    path: 'article-new/merkavanni',
    loadChildren: () => import('./pages/article-merkavanni/article-merkavanni.module').then(m => m.ArticleMerkavanniPageModule)
  },
  {
    path: 'ru/article-new/merkavanni',
    loadChildren: () => import('./pages/article-merkavanni/article-merkavanni.module').then(m => m.ArticleMerkavanniPageModule)
  },


  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'ru/categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'en/categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'pl/categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'vajna',
    loadChildren: () => import('./pages/war/war.module').then(m => m.WarPageModule)
  },
  {
    path: 'ru/voyna',
    loadChildren: () => import('./pages/war/war.module').then(m => m.WarPageModule)
  },
  {
    path: 'tag/:slug',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'ru/tag/:slug',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'en/tag/:slug',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'pl/tag/:slug',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'in-focus',
    loadChildren: () => import('./pages/objectives/objectives.module').then(m => m.ObjectivesPageModule)
  },
  {
    path: 'ru/in-focus',
    loadChildren: () => import('./pages/objectives/objectives.module').then(m => m.ObjectivesPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'ru/about',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'en/about',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'pl/about',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'about/contact',
    loadChildren: () => import('./pages/about/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'vacancy',
    loadChildren: () => import('./pages/vacancy/vacancy.module').then( m => m.VacancyPageModule)
  },
  {
    path: 'ru/vacancy',
    loadChildren: () => import('./pages/vacancy/vacancy.module').then( m => m.VacancyPageModule)
  },
  {
    path: 'ru/about/contact',
    loadChildren: () => import('./pages/about/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'en/about/contact',
    loadChildren: () => import('./pages/about/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'pl/about/contact',
    loadChildren: () => import('./pages/about/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'about/:slug',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'ru/about/:slug',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'en/about/:slug',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'pl/about/:slug',
    loadChildren: () => import('./pages/about/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'zatrymanyya-zhurnalisty',
    loadChildren: () => import('./pages/journalists-behind-bars/journalists-behind-bars.module').then(m => m.JournalistsBehindBarsPageModule)
  },
  {
    path: 'ru/zaderzhannye-zhurnalisty',
    loadChildren: () => import('./pages/journalists-behind-bars/journalists-behind-bars.module').then(m => m.JournalistsBehindBarsPageModule)
  },
  {
    path: 'en/detained-journalists',
    loadChildren: () => import('./pages/journalists-behind-bars/journalists-behind-bars.module')
      .then(m => m.JournalistsBehindBarsPageModule)
  },
  {
    path: 'pl/zatrzymani-dziennikarze',
    loadChildren: () => import('./pages/journalists-behind-bars/journalists-behind-bars.module')
      .then(m => m.JournalistsBehindBarsPageModule)
  },
  {
    path: 'news/31-10-2022-mestsami-nagadvae-prypyats-yak-harkauskaya-voblasts-vyartaetstsa-da-zhytstsya',
    loadChildren: () => import('./pages/fotostory/fotostory.module').then(m => m.FotostoryPageModule)
  },
  {
    path: 'news/:slug',
    loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule)
  },
  {
    path: 'ru/news/31-10-2022-mestami-napominaet-pripyat-kak-harkovskaya-oblast-vozvrashhaetsya-k-zhizni',
    loadChildren: () => import('./pages/fotostory-ru/fotostory-ru.module').then(m => m.FotostoryRuPageModule)
  },
  {
    path: 'ru/news/:slug',
    loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule)
  },
  {
    path: 'en/news/:slug',
    loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule)
  },
  {
    path: 'pl/news/:slug',
    loadChildren: () => import('./pages/article/article.module').then(m => m.ArticlePageModule)
  },
  {
    path: 'in-focus/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'ru/in-focus/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'telebachannie',
    loadChildren: () => import('./pages/online/online.module').then(m => m.OnlinePageModule)
  },
  {
    path: 'ru/telebachannie',
    loadChildren: () => import('./pages/online/online.module').then(m => m.OnlinePageModule)
  },
  {
    path: 'en/telebachannie',
    loadChildren: () => import('./pages/online/online.module').then(m => m.OnlinePageModule)
  },
  {
    path: 'pl/telebachannie',
    loadChildren: () => import('./pages/online/online.module').then(m => m.OnlinePageModule)
  },
  {
    path: 'programs-list',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'programs-list/:slug',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'ru/programs-list',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'ru/programs-list/:slug',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule)
  },
  {
    path: 'programs/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'ru/programs/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'en/programs/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'pl/programs/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'programs',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'ru/programs',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'en/programs',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'pl/programs',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'films',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'ru/films',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'en/films',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'pl/films',
    loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsPageModule)
  },
  {
    path: 'films/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'ru/films/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'en/films/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'pl/films/:slug',
    loadChildren: () => import('./pages/in-focus/in-focus.module').then(m => m.InFocusPageModule)
  },
  {
    path: 'film_directors/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'ru/film_directors/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'en/film_directors/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'pl/film_directors/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'film-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'ru/film-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'en/film-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'pl/film-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'program/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'ru/program/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'en/program/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'pl/program/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'program-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'ru/program-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'en/program-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'pl/program-category/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'program-archive/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'ru/program-archive/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'en/program-archive/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'pl/program-archive/:slug',
    loadChildren: () => import('./pages/program/program.module').then(m => m.ProgramPageModule)
  },
  {
    path: 'opinions',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'ru/opinions',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'en/opinions',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'pl/opinions',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'opinion/:slug',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'ru/opinion/:slug',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'en/opinion/:slug',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'pl/opinion/:slug',
    loadChildren: () => import('./pages/opinions/opinions.module').then(m => m.OpinionsPageModule)
  },
  {
    path: 'mapa-strony',
    loadChildren: () => import('./pages/mapy-strony-page/mapy-strony-page.module').then( m => m.MapyStronyPagePageModule)
  },
  {
    path: 'pl/mapa-strony',
    loadChildren: () => import('./pages/mapy-strony-page/mapy-strony-page.module').then( m => m.MapyStronyPagePageModule)
  },
  {
    path: 'ru/mapa-strony',
    loadChildren: () => import('./pages/mapy-strony-page/mapy-strony-page.module').then( m => m.MapyStronyPagePageModule)
  },

  {
    path: 'rassledavanni',
    loadChildren: () => import('./pages/custom-page/custom-page.module').then( m => m.CustomPagePageModule)
  },
  {
    path: 'ru/rassledovaniya',
    loadChildren: () => import('./pages/custom-page/custom-page.module').then( m => m.CustomPagePageModule)
  },
  {
    path: 'en/investigations',
    loadChildren: () => import('./pages/custom-page/custom-page.module').then( m => m.CustomPagePageModule)
  },
  {
    path: 'pl/sledztwa',
    loadChildren: () => import('./pages/custom-page/custom-page.module').then( m => m.CustomPagePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'ru/search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'en/search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'pl/search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  },
  {
    path: 'article-merkavanni',
    loadChildren: () => import('./pages/article-merkavanni/article-merkavanni.module').then( m => m.ArticleMerkavanniPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
